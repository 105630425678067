import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ร้านค้าเบนจามิน"
    }}>{`ร้านค้าเบนจามิน`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "เบนจามิน"
    }}>{`เบนจามิน`}</h3>
    <p>{`เบนจามินคือเซลล์แมนที่อยู่ในฟาร์มของคุณ สามารถกดไปที่เบนจามินเพื่อเปิดหน้าร้านค้าขึ้นมาได้`}</p>
    <p><img alt="mat" src={require("./public/images/benjamin.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      